import { AxiosResponse } from 'axios';
import { GET } from 'utils/useApi';
import moment from 'moment';
import { Booking,serialCourse } from 'types/Api';
import i18n from './i18n';
import { isUAE } from './env';

const { NODE_ENV } = process.env;
// const UK_POSTCODE_REGEX = /^((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9]?[A-Za-z]))))( {0,1})[0-9][A-Za-z]{2})$/;
const UK_POSTCODE_REGEX = /^(([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9]?[A-Za-z]))))(( {0,1})[0-9][A-Za-z]{2}){0,1}$/;
export const DUMMY_PHONE_NUMBER = '07777777777';

export const logSafelyToConsole = (value: any): void => {
  if (NODE_ENV === 'development') {
    // eslint-disable-next-line no-console
    // console.log(value);
  }
};

export const toTitleCase = (text: string | undefined): string | undefined => {
  if (text) {
    return text
      .replace(/(-|^)/g, ' ')
      .trim()
      .replace(/\w\S*/g, function(txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      })
      .replace('Gcse', 'GCSE')
      .replace('Igce', 'IGCE');
  } else return undefined;
};

export const isValidCurrentPassword = (password: string): boolean => {
  return password.length >= 8;
};

export const isValidEmail = (email: string): boolean => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const isValidVerification = (verificationCode: string): boolean => {
  return verificationCode.length == 6;
};

export const isValidName = (name: string): boolean => {
  return (
    name.length >= 4 &&
    name.split(' ').length > 1 &&
    name.split(' ')[0].length > 1 && name.split(' ')[0].length < 30 &&
    name.split(' ')[1].length > 1 && name.split(' ')[1].length < 30 &&
    !/[0-9]/.test(name)
  );
};

export const isValidSingleName = (name: string): boolean => {
  return name.length > 1 && name.split(' ').length === 1 && !/[0-9]/.test(name);
};

export const isValidSingleNameWithSpaces = (name: string): boolean => {
  return Boolean(name.length) && !/[0-9]/.test(name);
};

export const isValidPhone = (phone: string | null): boolean => {
  const phoneReg = /^1(3[0-9]|5[012356789]|7[678]|8[0-9])(?=\d{15}$)/g;
  if (phone === null) {
    return false;
  }
  return (
    phone.length >= 7 &&
    phone.length < 20 &&
    phone.indexOf(' ') === -1 &&
    !phoneReg.test(phone)
  );
};

export const isValidNewPassword = (password: string): boolean => {
  return (
    password.length >= 8 &&
    // /[a-z]/.test(password) &&
    // /[A-Z]/.test(password) &&
    // /\d/.test(password) &&
    // /\W/.test(password) &&
    password.indexOf(' ') === -1
  );
};

export const helperTextForPassword = (password: string): string | undefined => {
  if (isValidNewPassword(password)) return 'Your password looks good.';
  else if (password.indexOf(' ') !== -1)
    return 'Please do not include any spaces.';
  else if (password.length < 8)
    return 'At least 8 characters without spaces.';
  // else if (!/[a-z]/.test(password))
  //   return 'Please include at least one lower case letter.';
  // else if (!/[A-Z]/.test(password))
  //   return 'Please include at least one upper case letter.';
  // else if (!/\d/.test(password)) return 'Please include at least one number.';
  // else if (!/\W/.test(password))
  //   return 'Please include at least one symbol such as $, %, &, etc.';
  else return undefined;
};

export const isValidDBSCertificateNumber = (number: string): boolean => {
  return (
    (number.length === 12 || number.length === 16) &&
    number.indexOf(' ') === -1 &&
    number.substring(0, 2) === '00' &&
    number !== '000000000000' &&
    number !== '0000000000000000' &&
    !/[a-zA-Z]/.test(number)
  );
};

export const isValidDBSDateOfIssue = (
  date: moment.Moment | undefined
): boolean => {
  if (date) {
    return (
      date.isBefore(moment()) && date.isAfter(moment().subtract(3, 'years'))
    );
  } else return false;
};

export const isValidBirthDate = (date: moment.Moment | null): boolean => {
  if (date) {
    return date.isBefore(moment().subtract(18, 'years'));
  } else return false;
};

export const wordCount = (content: string): number => {
  return content.split(' ').length;
};

export const isValidExperienceSection = (content: string): boolean => {
  return wordCount(content) > 100;
};

export const isValidExperienceYears = (experienceYears: number): boolean => {
  return experienceYears >= 0 && experienceYears <= 40;
};

export const isValidPrice = (price: number): boolean => {
  const isUKPriceValid = price % 10 === 0 && price >= 20 && price <= 90;
  const isUAEPriceValid = price % 50 === 0 && price >= 50 && price <= 400;
  return isUAE ? isUAEPriceValid : isUKPriceValid
};

export const isValidTagline = (content: string | undefined): boolean => {
  return isTaglineLess41Chars(content) && isTaglineMore2Words(content) && isTaglineLess7Words(content);
};

export const isTaglineLess41Chars = (content: string | undefined): boolean => {
  return Boolean(content && content.length < 41);
};
export const isTaglineMore2Words = (content: string | undefined): boolean => {
  return Boolean(content && wordCount(content) > 2);
};
export const isTaglineLess7Words = (content: string | undefined): boolean => {
  return Boolean(content && wordCount(content) < 7);
};

export const priceForExperience = (experience: number | undefined | null): number => {
  if (experience === 1) return isUAE ? 50 : 20;
  if (experience === 8) return isUAE ? 100 : 30;
  if (experience === 9) return isUAE ? 150 : 40;
  if (experience === 3) return isUAE ? 200 : 50;
  if (experience === 5) return isUAE ? 250 : 60;
  if (experience === 7) return isUAE ? 300 : 70;
  if (experience === 10) return isUAE ? 350 : 80;
  if (experience === 11) return isUAE ? 400 : 90;
  return isUAE ? 150 : 40;
};

export const experienceForPrice = (price: number | undefined): number => {
  if (price === (isUAE ? 50 : 20)) return 1;
  if (price === (isUAE ? 100 : 30)) return 8;
  if (price === (isUAE ? 150 : 40)) return 9;
  if (price === (isUAE ? 200 : 50)) return 3;
  if (price === (isUAE ? 250 : 60)) return 5;
  if (price === (isUAE ? 300 : 70)) return 7;
  if (price === (isUAE ? 350 : 80)) return 10;
  if (price === (isUAE ? 400 : 90)) return 11;
  return 9;
};

export const experienceOptions = [
  {
    label: 'All Tutor',
    description: '- any level of experience',
    value: [0, 60]
  },
  {
    label: 'New Tutor',
    description: '- typically graduates with some teaching experience',
    value: [0, 1]
  },
  {
    label: 'Intermediate Tutor',
    description:
      '- these tutors have tutored many students over typically 2-4 years',
    value: [2, 4]
  },
  {
    label: 'Experienced Tutor',
    description: "- at least 5 years' tutoring experience",
    value: [5, 9]
  },
  {
    label: 'Professional Tutor',
    description: '- career tutors with over 10 years of experience',
    value: [10, 19]
  },
  {
    label: 'Expert Tutor',
    description:
      '- our most experienced individuals, with a career in education spanning at least 20 years',
    value: [20, 60]
  }
];

export const sanitizePostcode = (postcode: string): string => {
  if (isValidPostcode(postcode)) {
    postcode = postcode.replace(/\s/g, '');
    if (postcode.length > 4) {
      const outcode = postcode.substr(0, postcode.length - 3);
      const incode = postcode.substr(postcode.length - 3);
      return `${outcode} ${incode}`.toUpperCase();
    } else {
      return postcode.toUpperCase();
    }
  } else {
    return postcode.toUpperCase();
  }
};

export const sanitizePhoneNumber = (number: string): string => {
  return number.replace(/[^0-9]/g, '');
}

export const sanitizeInternationalNumber = (number = ''): string => {
  return number.replace(/[^0-9+]/g, '');
}

export const isValidPostcode = (postcode: string | undefined): boolean => {
  if (postcode) {
    let valid = false;
    if (postcode !== null && postcode.length >= 2) {
      valid = true;
    }
    if(isUAE){
      return valid;
    }else {
      return valid && UK_POSTCODE_REGEX.exec(postcode) !== null;
    }
  } else {
    return false;
  }
};

export const getMinutesFromDate = (date: Date | number): string => {
  const minutes = new Date(date).getMinutes();

  if (minutes < 10) {
    return `0${minutes}`;
  } else {
    return `${minutes}`;
  }
};

export const getHoursFromDate = (date: Date | number): string => {
  const hours = new Date(date).getHours();

  if (hours < 10) {
    return `0${hours}`;
  } else {
    return `${hours}`;
  }
};

export const goToClassRoom = (booking: Booking) => {
  const { REACT_APP_WHITEBOARD_URL } = process.env;
  const startTime = (+getHoursFromDate(booking.start_time)*60)+(+getMinutesFromDate(booking.start_time));
  const endTime = (+getHoursFromDate(booking.end_time)*60)+(+getMinutesFromDate(booking.end_time));
  const whiteboardId = String(booking.chat) + String(booking.customer.id) + String(booking.tutor.id);
  const disabledButton = !(enableWhiteBoardAccess(booking));

  if (!disabledButton) {
    const paramWhiteboard = `id=${whiteboardId}&startime=${startTime}&endtime=${endTime}`;

    window.open(`${REACT_APP_WHITEBOARD_URL}?${paramWhiteboard}`, "_blank");
  }
};

export const goToSerailClassRoom = (serialCourse: serialCourse) => {
  const { REACT_APP_WHITEBOARD_URL } = process.env;
  const startTime = (+getHoursFromDate(serialCourse.start_date)*60)+(+getMinutesFromDate(serialCourse.start_date));
  const endTime = (+getHoursFromDate(serialCourse.end_date)*60)+(+getMinutesFromDate(serialCourse.end_date));
  const whiteboardId = String(serialCourse.id) + String(serialCourse.tutor.id);
  const disabledButton = !(serialEnableWhiteBoardAccess(serialCourse));

  if (!disabledButton) {
    const paramWhiteboard = `id=${whiteboardId}&startime=${startTime}&endtime=${endTime}`;

    window.open(`${REACT_APP_WHITEBOARD_URL}?${paramWhiteboard}`, "_blank");
  }
};

export const getDateStringFromDate = (date: Date | number): string => {
  const options = {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  };

  const dateString = new Date(date).toLocaleDateString(i18n.isAr ? 'ar' : 'en-US', options);

  return dateString;
};

export const getShortDateStringFromDate = (date: Date | number): string => {
  const options = {
    weekday: 'short',
    month: 'short',
    day: 'numeric'
  };

  const dateString = new Date(date).toLocaleDateString(i18n.isAr ? 'ar' : 'en-US', options);

  return dateString;
};

export const bookingIsUnconfirmed = (booking: Booking): boolean =>
  booking.is_trial_call
  ? !booking.is_accepted_by_tutor && !booking.is_rejected_by_tutor
  : !booking.is_accepted_by_customer && !booking.is_rejected_by_customer;

export const bookingIsInThePast = (booking: Booking): boolean =>
  moment(booking.end_time).isBefore(moment());

export const bookingIsInTheFuture = (booking: Booking): boolean =>
  moment(booking.end_time).isAfter(moment());

export const serialCourseIsInThePast = (booking: serialCourse): boolean =>{
    const realEnd_time=booking.courses[booking.courses.length-1][1]
    return  moment(realEnd_time).isBefore(moment());
  }

export const serialCourseInTheFuture = (booking: serialCourse): boolean =>{
  const realEnd_time=booking.courses[booking.courses.length-1][1]
  return moment(realEnd_time).isAfter(moment());
}


export const enableWhiteBoardAccess = (booking:Booking): boolean => {
  return (moment().isAfter(moment(booking.start_time).subtract('15', 'minutes')) &&
  moment().isBefore(moment(booking.end_time).add('15', 'minutes')))

}
export const serialEnableWhiteBoardAccess = (booking:serialCourse): boolean => {
  let canEnable=false
  booking.courses.forEach((item:string[])=>{
    // console.log(booking.course_name,moment(item[0]),moment(),moment(item[1]))
    if(moment().isAfter(moment(item[0]).subtract('15', 'minutes'))&&moment().isBefore(moment(item[1]))){
      canEnable=true
      return
    }
  })
  return canEnable
}

export const validatePhoneNumber = async (phoneNumber: string): Promise<boolean> => {
  const url = `v3/validate/phone/?phone_number=${phoneNumber}`;
  try {
    const response = await GET(url, false) as AxiosResponse;
    return response.status === 200;
  } catch (error) {
    return Promise.resolve(false);
  }
};

export const getInitials = (name: string = '') => {
  if (name.trim().includes(' ')) {
    return name.split(' ').slice(0, 2).map(el => el[0]).join('').toUpperCase();
  }
  return name.substring(0, 2).toUpperCase();
}

export const getUnconfirmedBookings = (bookings: Booking[], isCustomer: boolean) => {
  const unConfirmedBookings = bookings.filter(el => {
    if (isCustomer) {
      return [
        !el.is_trial_call,
        !el.is_accepted_by_customer,
        !el.is_rejected_by_customer,
        (bookingIsInTheFuture(el) || enableWhiteBoardAccess(el))
      ].every(e => !!e);
    }
    return [
      el.is_trial_call,
      !el.is_accepted_by_tutor,
      !el.is_rejected_by_tutor,
      (bookingIsInTheFuture(el) || enableWhiteBoardAccess(el))
    ].every(e => !!e);
  });

  return unConfirmedBookings;
}
