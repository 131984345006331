import React, {useState, useReducer, useContext, useCallback} from 'react';
import {Form, Input, Button,  Select, Radio, notification} from 'antd';
import { Text } from 'rebass';
import {isValidEmail, isValidPhone, isValidVerification, sanitizePhoneNumber} from 'utils';
import { requestLogin, LoginDispatch } from './actions';
import reducer, { LoginState } from './reducer';
import { User } from 'types/Users';
import { AUTH_EXPIRY, AUTH_TOKEN, saveItem, USER_PROFILE } from 'utils/storage';
import AuthContext from 'context/AuthContext';
import css from './LoginForm.module.scss';
import { LoginRegisterSuccessCb } from 'utils/auth';
import { useTranslation } from 'react-i18next';
import { isUAE } from 'utils/env';
import IntlCodes from "utils/intlCodes.json";
import cn from "classnames";
import {POST} from "utils/useApi";
import i18n from "utils/i18n";
import {useGoogleReCaptcha} from "react-google-recaptcha-v3";
import {AxiosResponse} from "axios";

const { Item } = Form;

interface LoginProps {
  switchToCustomerOnboarding?: React.MouseEventHandler<HTMLElement>;
  switchToTutorOnboarding?: React.MouseEventHandler<HTMLElement>;
  switchToCreateStudent?: () => void;
  switchToCreateTutor?: () => void;
  switchToForgotPass: any;
  onSuccess: LoginRegisterSuccessCb;
  onError: Function;
}

export default function LoginForm({
  switchToCreateStudent,
  switchToCreateTutor,
  switchToForgotPass,
  onSuccess,
  onError
}: LoginProps) {
    const [activeTab, setActiveTab] = useState('Email');
  const auth = useContext(AuthContext);
  const { executeRecaptcha } = useGoogleReCaptcha();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [intlCode, setIntlCode] = useState( isUAE?'+971':'+44');
  const [invalidPhoneError, setInvalidPhoneError] = useState(false);
  const [showErrorForPhone, setShowErrorForPhone] = useState(false);
  const [loginMethod, setLoginMethod] = useState("email");
  const [phoneLoginMethod, setPhoneLoginMethod] = useState("password");
  const [smsVerificationCode, setSmsVerificationCode] = useState("");
  const validSmsVerification = isValidVerification(smsVerificationCode);

  const showErrorForSMSVerification = smsVerificationCode.length > 2 && !validSmsVerification;

  const [smsSendCountDown, setSmsSendCountDown] = useState(60);
  const [sendSmsTimerStatus, setSendSmsTimerStatus] = useState("Stop");
  const [{ loading, incorrectEmailOrPasswordError }, dispatch] = useReducer(
    reducer,
    {
      loading: false,
      incorrectEmailOrPasswordError: false
    }
  ) as [LoginState, LoginDispatch];

  const { t } = useTranslation()
  const tabs = [{
    name: 'Email',
    method: 'email',

    label: t("Email")
  },
  {
    name: 'Telephone',
    method: 'phone',
    label:t("Telephone")
  }
];
  const formIsValid = ():boolean => {
    if (loginMethod == "email") {
      return  isValidEmail(email)
    } else {
      return isValidPhone(phoneNumber)
    }
    return false;
  }
  function countdown() {
    let seconds = 60;
    setSendSmsTimerStatus('doing');
    function updateCountdown() {
      if (seconds <= 0) {
        clearInterval(countdownInterval);
      setSendSmsTimerStatus("Stop");
      }
      seconds--;
      setSmsSendCountDown(seconds)
    }
    updateCountdown();
    const countdownInterval = setInterval(updateCountdown, 1000);
  }
  const submitForm =async () => {
    let username = ""
    if (loginMethod == "email") {
      username = email
    } else {
      username = `${intlCode}${phoneNumber}`
    }
   const result=await requestLogin({username: username, method: loginMethod,password: password,phoneLoginMethod:phoneLoginMethod,sms_verification_code:smsVerificationCode},
      dispatch,
      onSuccessfulLogin,
      onError
    );

  };

  const storeCredentials = (user: User, token: string): void => {
    auth.setUser(user);
    auth.setAuthToken(token);
    saveItem(USER_PROFILE, JSON.stringify(user), AUTH_EXPIRY);
    saveItem(AUTH_TOKEN, token, AUTH_EXPIRY);
  };

  const onSuccessfulLogin: LoginRegisterSuccessCb = (user: User, token: string): void => {
    storeCredentials(user, token);
    onSuccess(user, token);
  };

  const { footer, loginBtn, goSignupText, goSignupBtn } = css;


  const onChange = (key: string) => {
    setLoginMethod(key)
  }
    const onRadioChange = (key: string) => {
    setPhoneLoginMethod(key)
  }

  const validPhone = isValidPhone(phoneNumber);
  const sendSMSVerification =async () => {
    if(sendSmsTimerStatus==="Stop"){
          countdown();
    }else {
      return
    }
    if (!isValidPhone(phoneNumber)) return;

     if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available');
      return;
     }
     const token = await executeRecaptcha('senloginSmsCode');

    const data: {[key: string]: any} = {
      phone: `${intlCode}${phoneNumber}`,
      recaptcha_token: token,
    };
    try {
      const result = (await POST(
        'v2/users/sms_verification_code/',
        data,
        false,
      )) as AxiosResponse;
      // @ts-ignore
     if (result.data.status == "ok") {
        notification.success({
          className: cn(i18n.isAr && 'isarNotification'),
          message: `${t('Success!')}`,
          description: `${t('A SMS verification code has been successfully sent to your phone')}`
        });
      } else {
         // @ts-ignore
        const  error_msg=result.data.error_msg
        notification.error({
          className: cn(i18n.isAr && 'isarNotification'),
          message: `${t('Oops!')}`,
          description:  error_msg ||`${t('Some error happen when send verification code')} `,
        });
      }
    } catch(e) {
      notification.error({
        className:cn(i18n.isAr&&'isarNotification'),
        message: `${t('Oops!')}`,
        description:e.response.data.phone || e.response.data.email || e.response.data.recaptcha_token || `${t('Some error happen when send verification code')}`,
      });
    }

  };
  return (
    <Form layout="vertical" className="ant-next ant-form-large">
      <div className={css.tabs}>
           {tabs.map(e => (
             <> <div key={e.name}
                     className={cn({
                                [css.tab]: true,
                                [css.active]: e.name === activeTab
                              })}
                              onClick={() => { setActiveTab(e.name);setLoginMethod(e.method) } }
                           >{e.label}</div>
             </>
             ))
           }
      </div>


        {activeTab=="Email"&& <div className={css.login_top_margin}>
             <Item
            hasFeedback={isValidEmail(email)}
            validateStatus="success"
            help={
              !incorrectEmailOrPasswordError &&
              email.length > 5 &&
              !isValidEmail(email)
                ? `${t('Please enter a valid email address')}`
                : ''
            }
          >
            <Input
              key="email"
              value={email}
              size="large"
              onChange={e => setEmail(e.target.value.toLowerCase())}
              disabled={loading}
              name="email"
              autoComplete="email"
              placeholder={t("Email")}
              onPressEnter={submitForm}
            />
          </Item>
          <Item
            validateStatus={incorrectEmailOrPasswordError ? 'error' : 'success'}
            help={
              incorrectEmailOrPasswordError
                ? `${t("Wrong email or password. Please check and try again")}`
                : password.length > 0 && password.length < 8
                ? `${t('Password may be too short Please check and try again')}`
                : ''
            }
          >
            <Input
              value={password}
              size="large"
              onChange={e => setPassword(e.target.value)}
              type="password"
              disabled={loading}
              name="password"
              autoComplete="password"
              placeholder={t("Password")}
              onPressEnter={submitForm}
            />
          </Item>
        </div>
        }

        {activeTab == "Telephone" && <div className={css.login_top_margin}>

          <Form.Item
            hasFeedback={validPhone || invalidPhoneError || showErrorForPhone}
            validateStatus={
              showErrorForPhone || invalidPhoneError
                ? 'error'
                : ''
            }
            help={
              invalidPhoneError
                ? `${t('Please check that you have entered a valid international code and phone number without any spaces')}`
                : showErrorForPhone
                  ? `${t('Please enter a valid phone number without any spaces')}`
                  : ''
            }
          >
            <Input
              value={phoneNumber}
              addonBefore={(
                <Select
                  className={css.phoneNumberSelector}
                  value={intlCode}
                  onChange={(option: string) => setIntlCode(option)}
                >
                  {IntlCodes.countries.map((country, idx) => (
                    <Select.Option key={idx} value={country.code} className={css.option}>
                      ({country.code}) <span className={css.mobile}>{country.short_name}</span>
                      <span className={css.desktop}>{country.name}</span>
                    </Select.Option>
                  ))}
                </Select>
              )}
              size="large"
              onChange={e => setPhoneNumber(sanitizePhoneNumber(e.target.value))}
              disabled={loading}
              autoComplete="phone"
              placeholder={t("Telephone")}
            />
          </Form.Item>
          <Item>
            <Radio.Group onChange={e => {
              onRadioChange((e.target.value))
            }} value={phoneLoginMethod}>
              <Radio value="password">{t("Password")}</Radio>
              <Radio value="otp">{t("OTP")}</Radio>
            </Radio.Group>
          </Item>
          {phoneLoginMethod === "password" &&
            <Item
              validateStatus={incorrectEmailOrPasswordError ? 'error' : 'success'}
              help={
                incorrectEmailOrPasswordError
                  ? `${t("Wrong phone or password. Please check and try again")}`
                  : password.length > 0 && password.length < 8
                    ? `${t('Password may be too short Please check and try again')}`
                    : ''
              }
            >
              <Input
                value={password}
                size="large"
                onChange={e => setPassword(e.target.value)}
                type="password"
                disabled={loading}
                name="password"
                autoComplete="password"
                placeholder={t("Password")}
                onPressEnter={submitForm}
              />
            </Item>
          }

          {
            phoneLoginMethod === "otp" &&
            <Form.Item
              hasFeedback={validSmsVerification}
              validateStatus={showErrorForSMSVerification ? 'error' : 'success'}
              help={
                showErrorForSMSVerification ? `${t('Please enter a valid OTP')}` : ''
              }
            >
              <Input
                value={smsVerificationCode}
                size="large"
                onChange={e => setSmsVerificationCode(e.target.value)}
                disabled={loading || !validPhone}
                autoComplete="sms-verification-code"
                placeholder={t("Enter OTP")}
                suffix={
                  <Button onClick={sendSMSVerification}
                          className={cn('btn-primary')}
                          disabled={!validPhone || sendSmsTimerStatus !== "Stop"}
                  >
                    {sendSmsTimerStatus === "Stop" ? t("Get OTP") : `Retry in ${smsSendCountDown}s`}
                  </Button>
                }
              />
            </Form.Item>
          }

        </div>
        }



      <div className={footer}>
        <span
          className={css.forgotPassword}
          onClick={switchToForgotPass}
        >
          <Text variant={isUAE?'link.black':'link.purple'}>
            {t('Forgot password?')}
          </Text>
        </span>
        <Button
          className={`ant-submit-btn ${loginBtn}`}
          disabled={!formIsValid()}
          loading={loading}
          onClick={submitForm}
        >
          {t('Login')}
        </Button>
          {!isUAE && switchToCreateStudent &&
            <div style={{ alignSelf: 'start' }}>
              <span className={goSignupText}>
                {t('Don\'t have an account?')}&nbsp;
              </span>
              <button
                className={goSignupBtn}
                onClick={() => { switchToCreateStudent() }}
              >
                {t('Sign up')}
              </button>
            </div>
          }
          {isUAE && switchToCreateStudent && switchToCreateTutor && <>
            <div style={{ alignSelf: 'start' }}>
              <span className={goSignupText}>
                {t('Don\'t have an account?')}&nbsp;
              </span>
            </div>
            <div style={{ alignSelf: 'start', margin: '0 -6px' }}>
              <button
                className={goSignupBtn}
                onClick={() => { switchToCreateStudent() }}
              >
                {t('Sign up as a student')}
              </button>
              &nbsp;{t('Or')}&nbsp;
              <button
                className={goSignupBtn}
                onClick={() => { switchToCreateTutor() }}
              >
                {t('Sign up as a tutor')}
              </button>
            </div>
            </>
          }
      </div>
    </Form>
  );
}
